import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { ValidatorForm } from 'react-material-ui-form-validator'

import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CustomTableCell from './CustomTableCell'

import Portal from '@material-ui/core/Portal'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'

import Loading from './Loading'

import { getPatient, getPatients, setPatientSearchQuery, addNewPatient } from '../actions'

import Icon from '@material-ui/core/Icon'

import '../styles/patientProfile.scss'
import compose from 'recompose/compose'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    // Only return patients for this group
    patients: state.patients.data && state.patients.data.filter((patient) => {
      return patient.group === ownProps.group._id
    }),
  }
}

const mapDispatchToProps = {
  getPatient,
  getPatients,
  setPatientSearchQuery,
  addNewPatient
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  row: {
    transition: '.2s',
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(25,50,74,.1)',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(113,113,113,.1)',
    },
    '&:hover': {
      backgroundColor: 'rgba(113,113,113,.3)',
    },
  },
})

class PatientTable extends React.Component {
  state = {
    newPatient: null,
    loading: true
  }

  async componentDidMount () {
    let component = this

    document.body.addEventListener('click', function (event) {
      if (event.target.parentElement.getAttribute('data-refresh')) {
        component.setState({ newPatient: null })
      }
    })
    // If the component mounts as the current tab, get the patients for this group
    if (this.props.page === this.props.index) {
      await this.props.getPatients({
        type: 'GET_PATIENTS',
        groupId: this.props.group._id,
        page: 0,
      })
      this.setState({ loading: false })
    }
  }

  async componentDidUpdate (prevProps) {
    // If the current tab changes
    if (prevProps.page !== this.props.page) {
      // And this group's tab is selected, get the patients for this group
      if (this.props.index === this.props.page) {
        this.setState({ loading: true })

        await this.props.getPatients({
          type: 'GET_PATIENTS',
          groupId: this.props.group._id,
          page: 0,
        })

        this.setState({ loading: false })
      }
    }
  }

  fetchMorePatients = () => {
    this.props.getPatients({
      type: 'GET_MORE_PATIENTS',
      groupId: this.props.group._id,
      page: this.props.patients.nextPage,
    })
  }

  addPatient () {
    this.setState({
      newPatient: true,
      dob: '',
      firstName: '',
      lastName: '',
      nickname: '',
      patientId: '',
      gender: 'male',
      email: '',
      phone: '',
      height: {
        feet: '',
        inches: '',
      },
      weight: '',
      emailPermission: {
        rideComplete: true,
        evalComplete: true
      }
    })
  }

  handleChange (event) {
    let name = event.target.name
    let emailPermission = this.state.emailPermission
    let height = this.state.height

    if (name === 'patientId') {
      if (this.state.patientId.length < 9 && event.target.value.length < 9) {
        this.setState({ [name]: event.target.value })
      }
    }

    else if (name === 'rideComplete' || name === 'evalComplete') {
      emailPermission[name] = event.target.checked
      this.setState({ emailPermission: emailPermission })
    }

    else if (name === 'feet' || name === 'inches') {
      height[name] = event.target.value
      this.setState({ height: height })
    }

    else {
      this.setState({ [name]: event.target.value })
    }
  }

  async submitPatient () {
    let dob = this.state.dob ? moment(this.state.dob).format() : null
    let patientHeight

    if (this.state.height.feet && this.state.height.inches) {
      patientHeight = Number(this.state.height.feet) * 12 + Number(this.state.height.inches)
    }

    let patient = {
      group: this.props.group._id,
      name: {
        given: this.state.firstName,
        family: this.state.lastName
      },
      phone: this.state.phone,
      dob: dob,
      email: this.state.email,
      nickname: this.state.nickname,
      gender: this.state.gender,
      patientID: this.state.patientId,
      height: patientHeight,
      weight: this.state.weight,
      emailPermission: {
        rideComplete: this.state.emailPermission.rideComplete,
        evalComplete: this.state.emailPermission.evalComplete,
      }
    }

    let addedPatient = await this.props.addNewPatient(patient)

    // If we didn't run into any errors
    if (addedPatient) {
      this.setState({ newPatient: null, addedPatient: addedPatient })
      // Get us over to the rider page for our newly created rider
      this.props.history.push('/rider/' + addedPatient._id + '/overview')
    }
  }

  filterPatients = _.debounce((searchQuery) => {
    this.props.getPatients({
      type: 'GET_PATIENTS',
      groupId: this.props.group._id,
      page: 0
    })
  }, 250)

  setSearchQuery = (event) => {
    window.scrollTo(0, 0)
    // Set the search query in the store first so we can use it in the UI right away
    this.props.setPatientSearchQuery(event.currentTarget.value)
    this.filterPatients()
  }

  render () {
    const { classes, theme, patients } = this.props
    let height, currentPage
    let hasMore = patients && patients.length < patients.total

    if (this.props.page !== this.props.index) {
      height = '75vh'
    }
    else {
      currentPage = true
    }

    if (patients && !this.state.loading) {
      return (
        <div className="page users" style={{ height: height }}>
          {
            !this.state.newPatient && currentPage &&
            <Portal container={document.body}>

              <div className="userSearch">
                <TextField
                  className="inputSearch"
                  fullWidth={true}
                  placeholder="Search for riders..."
                  classes={{ root: 'search' }}
                  type="text"
                  name="userSearch"
                  margin="normal"
                  variant="outlined"
                  value={this.props.patients.searchQuery}
                  onChange={(event) => this.setSearchQuery(event)}
                />
                <div onClick={() => this.addPatient()} className="addUser">
                  <Icon color="secondary">person_add</Icon>
                </div>
              </div>
            </Portal>
          }
          {
            this.state.newPatient &&
            <div className="addNewPatient">
              <h2>Add New Rider</h2>

              <ValidatorForm
                className="patientProfileForm"
                onSubmit={() => {
                  this.submitPatient()
                }}>
                <div className="flexRow">
                  <TextField
                    validators={['required']}
                    required
                    inputProps={{
                      name: 'firstName'
                    }}
                    label="First Name"
                    classes={{ root: 'form-group' }}
                    value={this.state.firstName}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <TextField
                    type="text"
                    required
                    inputProps={{
                      name: 'lastName'
                    }}
                    label="Last Name"
                    classes={{ root: 'form-group' }}
                    value={this.state.lastName}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                </div>

                <div className="flexRow">
                  <TextField
                    type="text"
                    inputProps={{
                      name: 'nickname'
                    }}
                    label="Nickname"
                    classes={{ root: 'form-group' }}
                    value={this.state.nickname}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />

                  <FormControl className={this.state.disabled ? 'disabled form-group' : 'form-group'}>
                    <InputLabel htmlFor="gender">Gender</InputLabel>
                    <Select
                      value={this.state.gender}
                      onChange={(event) => {
                        this.handleChange(event)
                      }}
                      inputProps={{
                        id: 'gender',
                        name: 'gender'
                      }}
                    >
                      <MenuItem value={''}></MenuItem>
                      <MenuItem value={'male'}>Male</MenuItem>
                      <MenuItem value={'female'}>Female</MenuItem>
                    </Select>
                  </FormControl>

                </div>

                <div className="flexRow">
                  <TextField
                    type="date"
                    required
                    inputProps={{
                      name: 'dob'
                    }}
                    label="Date of Birth"
                    classes={{ root: 'form-group' }}
                    value={moment(this.state.dob).format('YYYY-MM-DD')}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <TextField
                    inputProps={{
                      name: 'patientId',
                    }}
                    type="number"
                    label="Rider ID"
                    classes={{ root: 'form-group' }}
                    value={this.state.patientId}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                </div>

                <div className="flexRow">
                  <TextField
                    required
                    type="email"
                    validators={['required', 'isEmail']}
                    inputProps={{
                      name: 'email'
                    }}
                    label="Email Address"
                    classes={{ root: 'form-group' }}
                    value={this.state.email}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />

                  <TextField
                    required
                    inputProps={{
                      name: 'phone',
                      type: 'tel',
                    }}
                    label="Phone"
                    classes={{ root: 'form-group' }}
                    value={this.state.phone}
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                </div>

                <div className="flexRow heightWeight">
                  <div className="heightWrapper">
                    <TextField
                      className="inputHeight"
                      inputProps={{ name: 'feet', min: 3, max: 7, maxLength: 1 }}
                      inputlabelprops={{ shrink: true }}
                      type="number"
                      label="Height"
                      classes={{ root: 'form-group' }}
                      value={this.state.height.feet}
                      onChange={(event) => {
                        this.handleChange(event)
                      }}
                    />
                    <span>Feet</span>
                    <TextField
                      className="inputHeight inches"
                      inputProps={{ name: 'inches', min: 0, max: 11, maxLength: 2 }}
                      inputlabelprops={{ shrink: true }}
                      type="number"
                      label="Height"
                      classes={{ root: 'form-group' }}
                      value={this.state.height.inches}
                      onChange={(event) => {
                        this.handleChange(event)
                      }}
                    />
                    <span>Inches</span>
                  </div>
                  <div className="weightWrapper">
                    <TextField
                      inputProps={{ name: 'weight', maxLength: 3 }}
                      inputlabelprops={{ shrink: true }}
                      label="Weight"
                      type="number"
                      classes={{ root: 'form-group' }}
                      value={this.state.weight}
                      onChange={(event) => {
                        this.handleChange(event)
                      }}
                    />
                    <span>Lbs</span>
                  </div>
                </div>

                <h2>Email Preferences</h2>

                <div className="flexRow preferences">
                  <Checkbox
                    checked={this.state.emailPermission.rideComplete}
                    name='rideComplete'
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <p>Yes, I'd like to receive an email summary after rides</p>
                </div>

                <div className="flexRow preferences">
                  <Checkbox
                    checked={this.state.emailPermission.evalComplete}
                    name='evalComplete'
                    onChange={(event) => {
                      this.handleChange(event)
                    }}
                  />
                  <p>Yes, I'd like to receive an email summary after Core Evaluations</p>
                </div>

                <footer className="flexRow">
                  <Button onClick={() => this.setState({ newPatient: null })} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                  <Button type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
                </footer>

              </ValidatorForm>
            </div>
          }

          {
            !this.state.newPatient &&
            <InfiniteScroll
              loadMore={() => {
                this.fetchMorePatients()
              }}
              hasMore={hasMore}
              loader={
                <div style={{ marginTop: 20 }} key={0}>
                  <h3 className="loadingText">Fetching Riders...</h3>
                  <Loading/>
                </div>
              }>
              <Table className="patientTable">
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Name</CustomTableCell>
                    <CustomTableCell>Email</CustomTableCell>
                    <CustomTableCell>Rider ID</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    patients && patients.map((patient) => {
                      return (
                        <TableRow className={classes.row} key={patient._id}>
                          <CustomTableCell> <Link to={'/rider/' + patient._id + '/overview'}> {patient.name.given} {patient.name.family} </Link></CustomTableCell>
                          <CustomTableCell> <Link to={'/rider/' + patient._id + '/overview'}> {patient.email} </Link></CustomTableCell>
                          <CustomTableCell> <Link to={'/rider/' + patient._id + '/overview'}> {patient.patientID && patient.patientID} </Link></CustomTableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </InfiniteScroll>
          }
          {
            patients.searchQuery && patients.searchQuery.length > 0 && !patients &&
            <p style={{ textAlign: 'center', marginTop: 20 }}>No riders found.</p>
          }
        </div>
      )
    }
    if (this.state.loading) {
      return (
        <div className="page users empty">
          <h3 className="loadingText">Loading riders...</h3>
          <Loading/>
        </div>
      )
    }
    else {
      return false
    }
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientTable)

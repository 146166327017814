import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import '../styles/index.scss'
import '../styles/patientProfile.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
  }
}

class UserProfile extends React.Component {
  state = {
    firstName: this.props.currentUser.name.given,
    lastName: this.props.currentUser.name.family,
    isDisabled: true,
    email: this.props.currentUser.mail
  }

  render () {
    return (
      <div className="page patientProfile">
        <h2>My Profile</h2>
        <div className="patientProfileForm">
          <p style={{ marginTop: -20, textAlign: 'center', lineHeight: '16px', fontSize: 12 }}>To make changes to your profile, please contact an administrator.</p>
          <div className="flexRow">
            <TextField
              type="text"
              id="firstName"
              label="First Name"
              classes={{ root: 'form-group' }}
              className={this.state.isDisabled ? 'disabled' : ''}
              value={this.state.firstName}
            />
            <TextField
              type="text"
              id="lastName"
              label="Last Name"
              classes={{ root: 'form-group' }}
              className={this.state.isDisabled ? 'disabled' : ''}
              value={this.state.lastName}
            />
          </div>

          <div className="flexRow">
            <TextField
              type="email"
              id="email"
              label="Email Address"
              classes={{ root: 'form-group' }}
              className={this.state.isDisabled ? 'disabled' : ''}
              value={this.state.email}
            />
            <TextField
              id="phone"
              type="phone"
              label="Phone"
              style={{ pointerEvents: 'none', opacity: 0 }}
              classes={{ root: 'form-group' }}
              className={this.state.isDisabled ? 'disabled' : ''}
              value={this.state.phone}
            />
          </div>

        </div>

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(UserProfile)

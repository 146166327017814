import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'

import AnimationCount from 'react-count-animation'

import NumericInput from 'common/components/NumericInput'
import AssessmentChart from './AssessmentChart'
import Loading from './Loading'
import classNames from 'classnames'
import { questionnaires } from 'common/variables'
import { setTargetTab } from '../actions'

import '../styles/index.scss'
import '../styles/patientOverview.scss'

const styles = theme => (null)

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient
  }
}

const mapDispatchToProps = {
  setTargetTab
}

class PatientOverview extends React.Component {
  goToPage = (page) => {
    window.scrollTo(0, 0)
    switch (page) {
      case 'eval':
        this.props.history.push('/rider/' + this.props.currentPatient._id + '/run-ride')
        this.props.setTargetTab('evaluation')
        break
      case 'assessment':
        this.props.history.push('/rider/' + this.props.currentPatient._id + '/notes')
        this.props.setTargetTab('assessment')
        break
      case 'note':
        this.props.history.push('/rider/' + this.props.currentPatient._id + '/notes')
        this.props.setTargetTab('note')
        break
      case 'questionnaire':
        this.props.history.push('/rider/' + this.props.currentPatient._id + '/notes')
        this.props.setTargetTab('questionnaire')
        break
      default:
        this.props.history.push('/rider/' + this.props.currentPatient._id + '/run-ride')
        this.props.setTargetTab(this.props.currentPatient.lastRide ? 'lastRide' : 'custom')
    }
  }

  render () {
    const { isVisible, currentPatient, theme } = this.props
    const { assessmentResults, coreEvaluations, rides, lastQuestionnaire, lastRide, lastNote } = currentPatient

    let lastAssessment = []
    let isFetching = !!(!assessmentResults || !coreEvaluations)
    let questionnaireContent, possibleScore, totalScore, percentage, lastEvaluation

    if (currentPatient) {
      // Calculate the patient's personal best score
      // @todo how many different places do we calculate a patient's personal best score?
      let coreScores = rides && rides.map(ride => ride.coreScore).filter(Number)

      let personalBest = coreScores && Math.max(...coreScores)

      // Get the last Core Evaluation
      if (coreEvaluations && coreEvaluations.length) {
        lastEvaluation = coreEvaluations[coreEvaluations.length - 1]
      }

      // Build a normal assessment array so we can more easily render the last assessment
      if (currentPatient.lastAssessment && currentPatient.lastAssessment.values) {
        Object.keys(currentPatient.lastAssessment.values).forEach((value, index) => {
          let parameter = {}
          parameter[value] = Object.values(currentPatient.lastAssessment.values)[index]
          lastAssessment.push(parameter)
        })
      }

      // Set up last questionnaire content and scores
      if (lastQuestionnaire) {
        questionnaireContent = lastQuestionnaire._bundle === 'odi' ? questionnaires.ODI : questionnaires.NDI
        possibleScore = Object.keys(lastQuestionnaire.scores).length * 5
        totalScore = Object.values(lastQuestionnaire.scores).reduce((a, b) => a + b)
        percentage = ((totalScore / possibleScore) * 100).toFixed(0)
      }

      let scoreCount = {
        start: 0,
        duration: 2000,
        decimals: 0,
        animation: 'up',
      }

      return (
        <div className={classNames('page patientOverview', !isVisible && 'shrink')}>
          <h2>Rider Overview</h2>

          {
            !isFetching
              ? <div>
                <section className="ac360">
                  <header className="sectionHeader">
                    <h2>
                      <i className="icon ride"/>
                    Last Ride
                      { lastRide && <span>{moment(lastRide.startTime).format('MMMM Do, YYYY')}</span> }
                    </h2>
                    <i onClick={() => this.goToPage('normal')} className="icon add"/>
                  </header>

                  {
                    lastRide
                      ? <div className="rideContent">
                        <div className="flexRow" style={{ marginBottom: 20 }}>
                          <ol className="rideSummary">

                            {
                              lastRide.sets.map((set, index) => {
                                let direction
                                if (set.direction === 'CW') {
                                  direction = 'Clockwise'
                                }
                                else {
                                  direction = 'Counter Clockwise'
                                }

                                return (
                                  <li key={index}>
                                    <div className="parameter">
                                      <span className="number">{set.spins}</span>
                                      {set.spins > 1 ? 'Spins at' : 'Spin at'}
                                    </div>
                                    <div className="parameter">
                                      <span className="number">{set.degrees}</span>
                                Degrees
                                    </div>
                                    <div className="parameter">
                                      <i className={`direction ${set.direction.toLowerCase()}`}></i>
                                      <div className="setDirection">
                                        {direction}
                                      </div>
                                    </div>
                                  </li>
                                )
                              })
                            }
                          </ol>
                        </div>

                        {
                          lastRide.coreScore === personalBest
                            ? <div className="flexRow coreScoreWrapper best">
                              <div className="scoreItem">
                                <h3>Core Score</h3>
                                <span style={{ color: theme.palette.secondary.main }} className="score">
                                  <i className="icon trophy"/>
                                  <AnimationCount {...scoreCount} count={lastRide.coreScore} />
                                </span>
                                <footer style={{ color: theme.palette.secondary.main }} className="best">Personal Best</footer>
                              </div>
                            </div>
                            : <div className="flexRow coreScoreWrapper">
                              <div className="scoreItem">
                                <h3>Core Score</h3>
                                <span style={{ color: theme.palette.secondary.main }} className="score">
                                  <i className="icon star"/>
                                  <AnimationCount {...scoreCount} count={lastRide.coreScore} />
                                </span>
                              </div>
                              <div className="scoreItem">
                                <h3>Personal Best</h3>
                                <span style={{ color: theme.palette.secondary.main }} className="score">
                                  <i className="icon trophy"/>
                                  {personalBest}
                                </span>
                              </div>
                            </div>
                        }
                      </div>
                      : <em>You haven't taken your first ride.</em>
                  }
                </section>

                <section className="evaluationWrapper">
                  <header className="sectionHeader">
                    <h2>
                      <i className="icon eval"/>
                    Last Core Evaluation
                      { lastEvaluation && <span>{moment(lastEvaluation.createdAt).format('MMMM Do, YYYY')}</span> }
                    </h2>
                    <i onClick={() => this.goToPage('eval')} className="icon add"/>
                  </header>
                  {
                    lastEvaluation
                      ? <div className="flexRow">
                        <ol>
                          {
                            Object.keys(lastEvaluation.durations).map((quadrant, index) => {
                              let duration = Object.values(lastEvaluation.durations)[index]
                              let width = (duration / 120) * 100 + '%'

                              return (
                                <li style={{ backgroundColor: theme.palette.secondary.light }} key={index}>
                                  <div style={{ width: width, backgroundColor: theme.palette.secondary.main }} className="background"></div>
                                  <header>
                                    {quadrant}
                                    <span>{moment.utc(duration * 1000).format('m:ss')}</span>
                                  </header>
                                </li>
                              )
                            })
                          }
                        </ol>
                        <div className="summary" style={{ color: theme.palette.primary.main }}>
                          <h3>Angle</h3>
                          <span className="angle" style={{ color: theme.palette.secondary.main }}>{lastEvaluation.angle}</span>
                          <h3>Score</h3>
                          <span style={{ color: theme.palette.secondary.main }}>{lastEvaluation.score}</span>
                        </div>
                      </div>
                      : <em>You haven't performed a Core Evaluation yet.</em>
                  }
                </section>

                <section className="lastAssessment">
                  <header className="sectionHeader">
                    <h2>
                      <i className="icon assessment"/>
                    Last Assessment
                      { currentPatient.lastAssessment && currentPatient.lastAssessment.createdAt && <span>{moment(currentPatient.lastAssessment.createdAt).format('MMMM Do, YYYY')}</span> }
                    </h2>
                    <i onClick={() => this.goToPage('assessment')} className="icon add"/>
                  </header>

                  {
                    currentPatient.lastAssessment && lastAssessment
                      ? <div className="flexRow assessment">
                        {
                          lastAssessment.map((parameter, index) => {
                            let name = Object.keys(currentPatient.lastAssessment.values)[index]
                            let score = Object.values(currentPatient.lastAssessment.values)[index]
                            return (
                              <div key={index} className="assessmentParameter">
                                { index === 3 ? <header>Range of Motion</header> : <header>{name}</header> }
                                <AssessmentChart score={score} />
                                <div className="inputWrapper">
                                  <NumericInput
                                    disabled={true}
                                    color={theme.palette.primary.main}
                                    value={score} />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      : <em>You haven't completed an assessment yet.</em>
                  }
                </section>

                <section className="lastNote">
                  <header className="sectionHeader">
                    <h2>
                      <i className="icon note"/>
                      Last Note
                      { lastNote && <span>{moment(lastNote.createdAt).format('MMMM Do, YYYY')}</span> }
                    </h2>
                    <i onClick={() => this.goToPage('note')} className="icon add"/>
                  </header>
                  {
                    lastNote
                      ? <p className="lastNoteValue">{lastNote.value}</p>
                      : <em>You don't have any notes.</em>
                  }
                </section>

                <section className="questionnaireItem">

                  <header className="sectionHeader">
                    <h2>
                      <i className="icon questionnaire"/>
                      {
                        lastQuestionnaire
                          ? <div>Last {lastQuestionnaire._bundle.toUpperCase()} Questionnaire</div>
                          : <div>Last Questionnaire</div>
                      }
                      <span>{lastQuestionnaire && lastQuestionnaire.createdAt && moment(lastQuestionnaire.createdAt).format('MMMM Do, YYYY')}</span>
                    </h2>
                    <i onClick={() => this.goToPage('questionnaire')} className="icon add"/>
                  </header>

                  {
                    lastQuestionnaire
                      ? <div>
                        <div className="summary">
                          <span className="summaryItem">
                            <span className="label">Total Score</span>
                            <span style={{ color: theme.palette.secondary.main }}>{totalScore}</span>
                          </span>
                          <span className="summaryItem">
                            <span className="label">Percentage</span>
                            <span style={{ color: theme.palette.secondary.main }}>{percentage}</span>
                            <span style={{ color: theme.palette.secondary.main }} className="percentage">%</span>
                          </span>
                        </div>

                        <div className="questionnaireContent">
                          <div className="column">
                            {
                              questionnaireContent.map((question, questionIndex) => {
                                if (questionIndex < 5) {
                                  if (lastQuestionnaire.scores[questionIndex]) {
                                    return (
                                      <div key={questionIndex} className="questionItem">
                                        <header>
                                          <span style={{ color: theme.palette.secondary.main }}>{lastQuestionnaire.scores[questionIndex]}</span>
                                          {question.name}
                                        </header>
                                        <p>{question.options[lastQuestionnaire.scores[questionIndex]]}</p>
                                      </div>
                                    )
                                  }
                                  else {
                                    return (
                                      <div key={questionIndex} className="questionItem empty">
                                        <header>
                                          <span style={{ color: theme.palette.secondary.main }}>0</span>
                                          {question.name}
                                        </header>
                                      </div>
                                    )
                                  }
                                }
                                return true
                              })
                            }
                          </div>
                          <div className="column">
                            {
                              questionnaireContent.map((question, questionIndex) => {
                                if (questionIndex >= 5) {
                                  if (lastQuestionnaire.scores[questionIndex]) {
                                    return (
                                      <div key={questionIndex} className="questionItem">
                                        <header>
                                          <span style={{ color: theme.palette.secondary.main }}>{lastQuestionnaire.scores[questionIndex]}</span>
                                          {question.name}
                                        </header>
                                        <p>{question.options[lastQuestionnaire.scores[questionIndex]]}</p>
                                      </div>
                                    )
                                  }
                                  else {
                                    return (
                                      <div key={questionIndex} className="questionItem empty">
                                        <header>
                                          <span style={{ color: theme.palette.secondary.main }}>0</span>
                                          {question.name}
                                        </header>
                                      </div>
                                    )
                                  }
                                }
                                return true
                              })
                            }
                          </div>
                        </div>
                      </div>
                      : <em>You haven't completed any questionnaires yet.</em>
                  }
                </section>
              </div>
              : <div style={{ paddingTop: 150 }}>
                <Loading />
                <h3 className="loadingText">Fetching latest information...</h3>
              </div>
          }

        </div>

      )
    }
    else {
      return false
    }
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientOverview)

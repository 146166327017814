var questionnaires = {
  ODI: [{
    name: 'Pain Intensity',
    options: ['I have no pain at the moment', 'The pain is very mild at the moment', 'The pain is moderate at the moment', 'The pain is fairly severe at the moment', 'The pain is very severe at the moment', 'The pain is the worst imaginable at the moment']
  }, {
    name: 'Personal Care (Washing, Dressing, etc)',
    options: ['I can look after myself normally without causing extra pain', 'I can look after myself normally but it causes extra pain', 'It is painful to look after myself and I am slow and careful', 'I need some help but manage most of my personal care', 'I need help every day in most aspects of self-care', 'I do not get dressed, I wash with difficulty and stay in bed']
  }, {
    name: 'Lifting',
    options: ['I can lift heavy weights without extra pain', 'I can lift heavy weights but it gives extra pain', 'Pain prevents me from lifting heavy weights off the floor, but I can manage if they are conveniently placed (e.g. on a table)', 'Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned', 'I can lift very light weights', 'I cannot lift or carry anything at all']
  }, {
    name: 'Walking',
    options: ['Pain does not prevent me walking any distance', 'Pain prevents me from walking more than 1 mile', 'Pain prevents me from walking more than 1/2 mile', 'Pain prevents me from walking more than 100 yards', 'I can only walk using a stick or crutches', 'I am in bed most of the time']
  }, {
    name: 'Sitting',
    options: ['I can sit in any chair as long as I like', 'I can only sit in my favourite chair as long as I like', 'Pain prevents me from sitting more than one hour', 'Pain prevents me from sitting more than 30 minutes', 'Pain prevents me from sitting more than 10 minutes', 'Pain prevents me from sitting at all']
  }, {
    name: 'Standing',
    options: ['I can stand as long as I want without extra pain', 'I can stand as long as I want but it gives me extra pain', 'Pain prevents me from standing more than one hour', 'Pain prevents me from standing more than 30 minutes', 'Pain prevents me from standing more than 10 minutes', 'Pain prevents me from standing at all']
  }, {
    name: 'Sleeping',
    options: ['My sleep is never disturbed by pain', 'My sleep is occasionally disturbed by pain', 'Because of pain I have less than 6 hours sleep', 'Because of pain I have less than 4 hours sleep', 'Because of pain I have less than 2 hours sleep', 'Pain prevents me from sleeping at all']
  }, {
    name: 'Sex Life (if applicable)',
    options: ['My sex life is normal and causes no extra pain', 'My sex life is normal but causes some extra pain', 'My sex life is nearly normal but is very painful', 'My sex life is severely restricted by pain', 'My sex life is nearly absent because of pain', 'Pain prevents any sex life at all']
  }, {
    name: 'Social Life',
    options: ['My social life is normal and gives me no extra pain', 'My social life is normal but increases the degree of pain', 'Pain has no significant effect on my social life apart from limiting my more energetic interests (e.g. sport)', 'Pain has restricted my social life and I do not go out as often', 'Pain has restricted my social life to my home', 'I have no social life because of pain']
  }, {
    name: 'Travelling',
    options: ['I can travel anywhere without pain', 'I can travel anywhere but it gives me extra pain', 'Pain is bad but I manage journeys over two hours', 'Pain restricts me to journeys of less than one hour', 'Pain restricts me to short necessary journeys under 30 minutes', 'Pain prevents me from travelling except to receive treatment']
  }],
  NDI: [{
    name: 'Pain Intensity',
    options: ['I have no pain at the moment', 'The pain is very mild at the moment', 'The pain is moderate at the moment', 'The pain is fairly severe at the moment', 'The pain is very severe at the moment', 'The pain is the worst imaginable at the moment']
  }, {
    name: 'Personal Care (Washing, Dressing, etc)',
    options: ['I can look after myself normally without causing extra pain', 'I can look after myself normally but it causes extra pain', 'It is painful to look after myself and I am slow and careful', 'I need some help but manage most of my personal care', 'I need help every day in most aspects of self-care', 'I do not get dressed, I wash with difficulty and stay in bed']
  }, {
    name: 'Lifting',
    options: ['I can lift heavy weights without extra pain', 'I can lift heavy weights but it gives extra pain', 'Pain prevents me from lifting heavy weights off the floor, but I can manage if they are conveniently placed (e.g. on a table)', 'Pain prevents me from lifting heavy weights, but I can manage light to medium weights if the are conveniently positioned', 'I can lift very light weights', 'I cannot lift or carry anything at all']
  }, {
    name: 'Reading',
    options: ['I can read as much as I want to with no pain in my neck', 'I can read as much as I want to with slight pain in my neck', 'I can read as much as I want with moderate pain in my neck', "I can't read as much as I want because of moderate pain in my neck", 'I can hardly read at all because of severe pain in my neck', 'I cannot read at all']
  }, {
    name: 'Headaches',
    options: ['I have no headaches at all', 'I have slight headaches, which come infrequently', 'I have moderate headaches, which come infrequently', 'I have moderate headaches, which come frequently', 'I have severe headaches, which come frequently', 'I have headaches almost all the time']
  }, {
    name: 'Concentration',
    options: ['I can concentrate fully when I want to with no difficulty', 'I can concentrate fully when I want to with slight difficulty', 'I have a fair degree of difficulty in concentrating when I want to', 'I have a lot of difficulty in concentrating when I want to', 'I have a great deal of difficulty in concentrating when I want to', 'I cannot concentrate at all']
  }, {
    name: 'Work',
    options: ['I can do as much work as I want to', 'I can only do my usual work, but no more', 'I can do most of my usual work, but no more', 'I cannot do my usual work', 'I can hardly do any work at all', "I can\u2019t do any work at all"]
  }, {
    name: 'Driving',
    options: ['I can drive my car without any neck pain', 'I can drive my car as long as I want with slight pain in my neck', 'I can drive my car as long as I want with moderate pain in my neck', "I can\u2019t drive my car as long as I want because of moderate pain in my neck", 'I can hardly drive at all because of severe pain in my neck', "I can\u2019t drive my car at all"]
  }, {
    name: 'Sleeping',
    options: ['I have no trouble sleeping', 'My sleep is slightly disturbed (less than 1 hr sleepless)', 'My sleep is mildly disturbed (1-2 hrs sleepless)', 'My sleep is moderately disturbed (2-3 hrs sleepless)', 'My sleep is greatly disturbed (3-5 hrs sleepless)', 'My sleep is completely disturbed (5-7 hrs sleepless)']
  }, {
    name: 'Recreation',
    options: ['I am able to engage in all my recreation activities with no neck pain at all', 'I am able to engage in all my recreation activities, with some pain in my neck', 'I am able to engage in most, but not all of my usual recreation activities because of pain in my neck', 'I am able to engage in a few of my usual recreation activities because of pain in my neck', 'I can hardly do any recreation activities because of pain in my neck', "I can\u2019t do any recreation activities at all"]
  }]
};
var gender = [{
  id: 'male',
  name: 'Male'
}, {
  id: 'female',
  name: 'Female'
}];
var rideTemplates = [{
  id: 'steadyBurn',
  name: 'Steady Burn',
  description: '5 x 5',
  maxAngle: 100,
  sets: [{
    spins: 5,
    direction: 'CW',
    angleModifier: 0
  }, {
    spins: 5,
    direction: 'CCW',
    angleModifier: 0
  }]
}, {
  id: 'middlePassage',
  name: 'Middle Passage',
  description: 'Warm up, work, cool down',
  maxAngle: 78,
  sets: [{
    spins: 1,
    direction: 'CW',
    angleModifier: 0.06
  }, {
    spins: 1,
    direction: 'CCW',
    angleModifier: 0.06
  }, {
    spins: 3,
    direction: 'CW',
    angleModifier: -0.05
  }, {
    spins: 3,
    direction: 'CCW',
    angleModifier: -0.05
  }, {
    spins: 1,
    direction: 'CW',
    angleModifier: 0.06
  }, {
    spins: 1,
    direction: 'CCW',
    angleModifier: 0.06
  }]
}, {
  id: 'theGrind',
  name: 'The Grind',
  description: 'Warm up, work, deep dive',
  maxAngle: 78,
  sets: [{
    spins: 1,
    direction: 'CW',
    angleModifier: 0.06
  }, {
    spins: 1,
    direction: 'CCW',
    angleModifier: 0.06
  }, {
    spins: 3,
    direction: 'CW',
    angleModifier: 0
  }, {
    spins: 3,
    direction: 'CCW',
    angleModifier: 0
  }, {
    spins: 1,
    direction: 'CW',
    angleModifier: -0.06
  }, {
    spins: 1,
    direction: 'CCW',
    angleModifier: -0.06
  }]
}];
module.exports = {
  questionnaires: questionnaires,
  gender: gender,
  rideTemplates: rideTemplates
};
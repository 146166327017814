import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from 'common/actions'

import '../styles/index.scss'

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient,
  }
}

const mapDispatchToProps = {
  logout
}

class Navbar extends React.Component {
  render () {
    let brandStyle = {
      textDecoration: 'none',
      display: 'flex',
      color: 'white',
      alignItems: 'center',
      width: 100,
      height: 50,
      zIndex: 20
    }

    const { title } = this.props

    return (
      <div className="navbar">
        <Link data-refresh={true} style={brandStyle} to='/'>
          <div className="brand">
            <span className="appTitle">MANAGE</span>
          </div>
        </Link>
        <h1>{title}</h1>
        <i onClick={() => this.props.logout()} className="icon logout"/>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)

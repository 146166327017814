import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import classNames from 'classnames'

import { ValidatorForm } from 'react-material-ui-form-validator'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'

import { updatePatient } from '../actions'

import '../styles/index.scss'
import '../styles/patientProfile.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    currentGroup: state.currentUser.groups[0],
    currentPatient: state.currentPatient
  }
}

const mapDispatchToProps = {
  updatePatient
}

class PatientProfile extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    dob: '',
    patientId: '',
    _id: this.props.patientId,
    editing: false,
    phone: '',
    email: '',
    nickname: '',
    gender: '',
    weight: '',
    height: {
      feet: '',
      inches: '',
    },
    prevPatient: null,
    emailPermission: {
      rideComplete: true,
      evalComplete: true
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.currentPatient !== this.props.currentPatient) {
      this.refreshPatientInfo()
    }
  }

  componentDidMount () {
    this.refreshPatientInfo()
  }

  refreshPatientInfo () {
    // Check for all available fields and replace the state if they're there
    let patient = this.props.currentPatient

    if (patient.phone) {
      this.setState({ phone: patient.phone })
    }
    if (patient.email) {
      this.setState({ email: patient.email })
    }
    if (patient.nickname) {
      this.setState({ nickname: patient.nickname })
    }
    if (patient.gender) {
      this.setState({ gender: patient.gender })
    }
    if (patient.weight) {
      this.setState({ weight: patient.weight })
    }
    if (patient.height) {
      this.setState({
        height: {
          feet: Math.floor(patient.height / 12),
          inches: Math.floor(patient.height % 12)
        }
      })
    }
    if (patient.dob) {
      this.setState({ dob: patient.dob })
    }
    if (patient.emailPermission) {
      this.setState({ emailPermission: patient.emailPermission })
    }
    if (patient.name) {
      if (patient.name.given) {
        this.setState({ firstName: patient.name.given })
      }
      if (patient.name.family) {
        this.setState({ lastName: patient.name.family })
      }
    }
    if (patient.patientID) {
      this.setState({ patientId: patient.patientID })
    }
  }

  editProfile = () => {
    let { currentPatient } = this.props

    let nickname = currentPatient.nickname ? currentPatient.nickname : ''
    let gender = currentPatient.gender ? currentPatient.gender : ''
    let weight = currentPatient.weight ? currentPatient.weight : ''
    let feet = currentPatient.height ? Math.floor(currentPatient.height / 12) : ''
    let inches = currentPatient.height ? Math.floor(currentPatient.height % 12) : ''

    this.setState({
      editing: true,
      prevPatient: {
        firstName: this.props.currentPatient.name.given,
        lastName: this.props.currentPatient.name.family,
        dob: this.props.currentPatient.dob,
        patientId: this.props.currentPatient.patientID,
        _id: this.props.currentPatient._id,
        phone: this.props.currentPatient.phone,
        email: this.props.currentPatient.email,
        nickname: nickname,
        gender: gender,
        weight: weight,
        height: {
          feet: feet,
          inches: inches
        }
      }
    })
  }

  handleChange (event) {
    this.editProfile()

    let name = event.target.name
    let emailPermission = { ...this.state.emailPermission }
    let height = { ...this.state.height }

    if (name === 'rideComplete' || name === 'evalComplete') {
      emailPermission[name] = event.target.checked
      this.setState({ emailPermission: emailPermission })
    }
    if (name === 'feet' || name === 'inches') {
      height[name] = event.target.value
      this.setState({ height: height })
    }
    else {
      this.setState({ [name]: event.target.value })
    }
  }

  cancelEdit = () => {
    let { prevPatient } = this.state
    let { currentPatient } = this.props

    let emailPermission
    if (currentPatient.emailPermission) {
      emailPermission = currentPatient.emailPermission
    }
    else {
      emailPermission = {
        rideComplete: true,
        evalComplete: true
      }
    }

    this.setState({
      editing: false,
      firstName: currentPatient.name.given,
      lastName: currentPatient.name.family,
      dob: currentPatient.dob,
      patientId: currentPatient.patientID,
      _id: currentPatient._id,
      phone: prevPatient.phone,
      email: prevPatient.email,
      nickname: prevPatient.nickname,
      gender: prevPatient.gender,
      weight: prevPatient.weight,
      height: {
        feet: prevPatient.height.feet,
        inches: prevPatient.height.inches,
      },
      emailPermission: emailPermission,
      prevPatient: null
    })
  }

  submit () {
    // Get the patient object ready to patch
    let patientHeight
    if (this.state.height.feet && this.state.height.inches) {
      patientHeight = Number(this.state.height.feet) * 12 + Number(this.state.height.inches)
    }

    let patientInfo = {
      name: {
        given: this.state.firstName,
        middle: '',
        family: this.state.lastName,
        generational: ''
      },
      phone: this.state.phone,
      dob: moment(this.state.dob).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      email: this.state.email,
      gender: this.state.gender,
      nickname: this.state.nickname,
      height: patientHeight,
      weight: this.state.weight,
      emailPermission: {
        rideComplete: this.state.emailPermission.rideComplete,
        evalComplete: this.state.emailPermission.evalComplete,
      },
      _bundle: 'patient',
      _id: this.props.currentPatient._id
    }

    if (this.state.gender.length > 1) {
      patientInfo.gender = this.state.gender
    }

    this.props.updatePatient(patientInfo)
    this.setState({ prevPatient: null, editing: false })
  }

  render () {
    const { isVisible, theme, currentPatient } = this.props

    let waiverLink = 'https://www.smartwaiver.com/w/' + process.env.REACT_APP_SMARTWAIVER_ID + '/web/?wautofill_firstname=' + currentPatient.name.given + '&wautofill_lastname=' + currentPatient.name.family + '&wautofill_dobyyyymmdd=' + moment(currentPatient.dob).format('YYYYMMDD') + '&wautofill_tag=' + currentPatient._id + '&auto_anyoneelseneedtosign=0'
    let showWaiver = currentPatient.waiverSignedAt || this.props.currentGroup.isRiderWaiverRequired

    if (moment(currentPatient.dob).isAfter(moment().subtract(18, 'years'))) {
      waiverLink = 'https://waiver.smartwaiver.com/auto/?auto_waiverid=' + process.env.REACT_APP_SMARTWAIVER_ID + '&auto_tag=' + currentPatient._id + '&auto_anyoneelseneedtosign=0'
    }
    return (
      <div className={classNames('page patientProfile', !isVisible && 'shrink')}>
        <h2>Rider Profile</h2>

        <ValidatorForm
          className="patientProfileForm"
          onSubmit={() => {
            this.submit()
          }}>
          <div className="flexRow">
            <TextField
              inputlabelprops={{ shrink: true }}
              type="text"
              validators={['required']}
              required
              inputProps={{
                name: 'firstName'
              }}
              label="First Name"
              classes={{ root: 'form-group' }}
              value={this.state.firstName}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
            <TextField
              inputlabelprops={{ shrink: true }}
              type="text"
              validators={['required']}
              required
              inputProps={{
                name: 'lastName'
              }}
              label="Last Name"
              classes={{ root: 'form-group' }}
              value={this.state.lastName}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
          </div>

          <div className="flexRow">
            <TextField
              inputlabelprops={{ shrink: true }}
              type="text"
              inputProps={{
                name: 'nickname'
              }}
              label="Nickname"
              classes={{ root: 'form-group' }}
              value={this.state.nickname}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />

            <FormControl className="form-group">
              <InputLabel htmlFor="gender">Gender</InputLabel>
              <Select
                inputlabelprops={{ shrink: true }}
                value={this.state.gender}
                onChange={(event) => {
                  this.handleChange(event)
                }}
                inputProps={{
                  id: 'gender',
                  name: 'gender'
                }}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'male'}>Male</MenuItem>
                <MenuItem value={'female'}>Female</MenuItem>
              </Select>
            </FormControl>

          </div>

          <div className="flexRow">
            <TextField
              inputlabelprops={{ shrink: true }}
              type="date"
              required
              inputProps={{
                name: 'dob'
              }}
              label="Date of Birth"
              classes={{ root: 'form-group' }}
              value={moment(this.state.dob).format('YYYY-MM-DD')}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
            <TextField
              inputProps={{
                name: 'patientId',
                maxLength: 8
              }}
              disabled={!!this.state.patientId}
              inputlabelprops={{ shrink: true }}
              type="number"
              label="Rider ID"
              required
              classes={{ root: 'form-group' }}
              className={'disabled'}
              value={this.state.patientId}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
          </div>

          <div className="flexRow">
            <TextField
              inputlabelprops={{ shrink: true }}
              required
              type="email"
              validators={['required', 'isEmail']}
              inputProps={{
                name: 'email'
              }}
              label="Email Address"
              classes={{ root: 'form-group' }}
              value={this.state.email}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
            <TextField
              inputlabelprops={{ shrink: true }}
              required
              inputProps={{
                name: 'phone',
                type: 'tel',
              }}
              label="Phone"
              classes={{ root: 'form-group' }}
              value={this.state.phone}
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
          </div>

          <div className="flexRow heightWeight">
            <div className="heightWrapper">
              <TextField
                className="inputHeight"
                inputProps={{ name: 'feet', min: 3, max: 7, maxLength: 1 }}
                inputlabelprops={{ shrink: true }}
                type="number"
                label="Height"
                classes={{ root: 'form-group' }}
                value={this.state.height.feet}
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
              <span>Feet</span>
              <TextField
                className="inputHeight inches"
                inputProps={{ name: 'inches', min: 0, max: 11, maxLength: 2 }}
                inputlabelprops={{ shrink: true }}
                type="number"
                label="Height"
                classes={{ root: 'form-group' }}
                value={this.state.height.inches}
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
              <span>Inches</span>
            </div>
            <div className="weightWrapper">
              <TextField
                inputProps={{ name: 'weight', maxLength: 3 }}
                inputlabelprops={{ shrink: true }}
                label="Weight"
                type="number"
                classes={{ root: 'form-group' }}
                value={this.state.weight}
                onChange={(event) => {
                  this.handleChange(event)
                }}
              />
              <span>Lbs</span>
            </div>
          </div>

          {
            showWaiver &&
            <>
              <h2>Participant Release & Liability Waiver</h2>
              <div className="flexRow waiverStatus">
                {
                  currentPatient.waiverSignedAt
                    ? <p><span className="icon" />Signed on {moment(currentPatient.waiverSignedAt).format('MMMM Do, YYYY')}</p>
                    : <a href={waiverLink}>
                      <Button variant="contained" style={theme.button.primary} className='button'>
                        Sign Waiver
                      </Button>
                    </a>
                }
              </div>
            </>
          }

          <h2>Email Preferences</h2>

          <div className="flexRow preferences">
            <Checkbox
              checked={this.state.emailPermission.rideComplete}
              name='rideComplete'
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
            <p>Yes, I'd like to receive an email summary after rides</p>
          </div>

          <div className="flexRow preferences">
            <Checkbox
              checked={this.state.emailPermission.evalComplete}
              name='evalComplete'
              onChange={(event) => {
                this.handleChange(event)
              }}
            />
            <p>Yes, I'd like to receive an email summary after Core Evaluations</p>
          </div>

          {
            this.state.editing &&
            <footer className="flexRow">
              <Button onClick={this.cancelEdit} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
              <Button type="submit" variant="contained" style={theme.button.primary} className="button">Save Changes</Button>
            </footer>
          }
        </ValidatorForm>

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientProfile)

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TabsDateRange from './TabsDateRange'
import ListExpandable from './ListExpandable'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'

import SwipeableViews from 'react-swipeable-views'

import LineGraph from './LineGraph'
import Switch from '@material-ui/core/Switch'
import classNames from 'classnames'

import '../styles/patients.scss'
import 'react-count-animation/dist/count.min.css'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { getCoreEvaluations, getPatientRides } from '../actions'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient
  }
}

const mapDispatchToProps = {
  getCoreEvaluations,
  getPatientRides
}

class AllCore360 extends React.Component {
  state = {
    allCoreGraphData: [],
    currentGraphData: [],
    currentEvalGraphData: [],
    rideHistory: [],
    activeTab: 'dateRange',
    graphSet: 'rideData',
    graphSetIndex: 1,
    evalOnly: false,
    rideData: {
      totalMonth: null,
      totalQuarter: null,
      totalYear: null,
      allTime: null,
    },
    dateSelectHistory: true,
    rideHistoryStart: moment().subtract(90, 'days').format('YYYY-MM-DD'),
    rideHistoryEnd: moment().format('YYYY-MM-DD'),
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.currentPatient.rides !== this.props.currentPatient.rides) {
      this.updateRideData()
    }
  }

  updateRideData () {
    let rides = this.props.currentPatient.rides
    let allCoreGraphData = this.props.currentPatient.allCoreGraphData
    let coreEvaluationGraphData = this.props.currentPatient.coreEvaluationGraphData

    let last90History
    let last90GraphData

    if (rides && allCoreGraphData) {
      // Start by filtering history by last 90 days
      last90History = rides.filter((session) => {
        return moment(session.startTime).isBetween(moment().subtract(90, 'days'), moment())
      })

      // Set the initial ride history list and rideData
      this.setState({ rideHistory: last90History, rideData: this.props.currentPatient.rideData })

      // Set up the current data for the graph
      last90GraphData = allCoreGraphData.filter((day) => {
        return moment(day.startTime).isBetween(moment().subtract(90, 'days'), moment())
      })

      if (coreEvaluationGraphData) {
        let last90Evaluations = coreEvaluationGraphData.filter((day) => {
          return moment(day.startTime).isBetween(moment().subtract(90, 'days'), moment())
        })

        this.setState({ currentEvalGraphData: last90Evaluations })
      }

      this.setState({ currentGraphData: last90GraphData })

      // Get all the core scores to see the personal best
      let coreScores = rides.map(ride => ride.coreScore).filter(Number)
      this.setState({ personalBest: Math.max.apply(null, coreScores) })
    }
  }

  componentDidMount () {
    this.getRideDataContent()
  }

  async getRideDataContent () {
    await this.props.getPatientRides(this.props.patientId)
    await this.props.getCoreEvaluations(this.props.patientId)
    this.updateRideData()
  }

  handleTabChange (activeTab, start, end) {
    this.setState({ activeTab: activeTab })

    let rides = this.props.currentPatient.rides
    let allCoreGraphData = this.props.currentPatient.allCoreGraphData
    let coreEvaluationGraphData = this.props.currentPatient.coreEvaluationGraphData

    switch (activeTab) {
      // This Year
      case 'thisYear':

        let thisYear = rides.filter((session) => {
          return moment(session.startTime).isBetween(moment().startOf('year'), moment().endOf('year'))
        })

        let thisYearGraph = allCoreGraphData.filter((day) => {
          return moment(day.startTime).isBetween(moment().startOf('year'), moment().endOf('year'))
        })

        if (coreEvaluationGraphData) {
          let thisYearEvalGraph = coreEvaluationGraphData.filter((day) => {
            return moment(day.date).isBetween(moment().startOf('year'), moment().endOf('year'))
          })
          this.setState({ currentEvalGraphData: thisYearEvalGraph })
        }

        this.setState({ rideHistory: thisYear, currentGraphData: thisYearGraph, dateSelectHistory: false })
        break

      // This Quarter
      case 'thisQuarter':

        let thisQuarter = rides.filter((session) => {
          return moment(session.startTime).isBetween(moment().startOf('quarter'), moment().endOf('quarter'))
        })

        let thisQuarterGraph = allCoreGraphData.filter((day) => {
          return moment(day.startTime).isBetween(moment().startOf('quarter'), moment().endOf('quarter'))
        })

        if (coreEvaluationGraphData) {
          let thisQuarterEvalGraph = coreEvaluationGraphData.filter((day) => {
            return moment(day.date).isBetween(moment().startOf('quarter'), moment().endOf('quarter'))
          })
          this.setState({ currentEvalGraphData: thisQuarterEvalGraph })
        }

        this.setState({ rideHistory: thisQuarter, currentGraphData: thisQuarterGraph, dateSelectHistory: false })
        break

      // This Month
      case 'thisMonth':

        let thisMonth = rides.filter((session) => {
          return moment(session.startTime).isBetween(moment().startOf('month'), moment().endOf('month'))
        })

        let thisMonthGraph = allCoreGraphData.filter((day) => {
          return moment(day.startTime).isBetween(moment().startOf('month'), moment().endOf('month'))
        })

        if (coreEvaluationGraphData) {
          let thisMonthEvalGraph = coreEvaluationGraphData.filter((day) => {
            return moment(day.date).isBetween(moment().startOf('month'), moment().endOf('month'))
          })
          this.setState({ currentEvalGraphData: thisMonthEvalGraph })
        }

        this.setState({ rideHistory: thisMonth, currentGraphData: thisMonthGraph, dateSelectHistory: false })
        break
      // Date Range
      case 'dateRange':
        let dateRange = rides.filter((session) => {
          return moment(session.startTime).isBetween(moment(start), moment(end).add(1, 'day'))
        })
        let dateRangeGraph = allCoreGraphData.filter((day) => {
          return moment(day.startTime).isBetween(moment(start), moment(end).add(1, 'day'))
        })

        if (coreEvaluationGraphData) {
          let dateRangeEvalGraph = coreEvaluationGraphData.filter((day) => {
            return moment(day.date).isBetween(moment(start), moment(end).add(1, 'day'))
          })
          this.setState({ currentEvalGraphData: dateRangeEvalGraph })
        }

        this.setState({ rideHistory: dateRange, currentGraphData: dateRangeGraph, dateSelectHistory: true })
        break

      default:
        this.setState({ rideHistory: rides, dateSelectHistory: false })
    }
  }

  handleHistoryDateChange (event, date) {
    switch (date) {
      case 'start':
        this.setState({ rideHistoryStart: event.currentTarget.value })
        this.handleTabChange('dateRange', event.currentTarget.value, this.state.rideHistoryEnd)
        break
      case 'end':
        this.setState({ rideHistoryEnd: event.currentTarget.value })
        this.handleTabChange('dateRange', this.state.rideHistoryStart, event.currentTarget.value)
        break
      default:
        return true
    }
  }

  changeGraphSet = (event, graphSet) => {
    this.setState({ graphSet })

    if (graphSet === 'coreScore') {
      this.setState({ graphSetIndex: 0 })
    }
    if (graphSet === 'rideData') {
      this.setState({ graphSetIndex: 1 })
    }
    if (graphSet === 'coreEvaluation') {
      this.setState({ graphSetIndex: 2 })
    }
  }

  onGraphSwipe = index => {
    this.setState({ graphSetIndex: index })

    if (index === 0) {
      this.setState({ graphSet: 'coreScore' })
    }
    if (index === 1) {
      this.setState({ graphSet: 'rideData' })
    }
    if (index === 2) {
      this.setState({ graphSet: 'coreEvaluation' })
    }
  }

  toggleEvalOnly = () => {
    this.setState({ evalOnly: !this.state.evalOnly })
  }

  render () {
    const { isVisible, theme } = this.props

    let graphSpinData = []
    let graphAngleData = []
    let coreScoreData = []
    let coreEvaluationData = []

    this.state.currentGraphData.forEach((day) => {
      graphSpinData.push({ x: moment(day.startTime).format('YYYY-MM-DD'), y: day.spins })
      graphAngleData.push({ x: moment(day.startTime).format('YYYY-MM-DD'), y: day.angle })
      coreScoreData.push({ x: moment(day.startTime).format('YYYY-MM-DD'), y: day.coreScore })
    })

    this.state.currentEvalGraphData.forEach((day) => {
      coreEvaluationData.push({ x: moment(day.startTime).format('YYYY-MM-DD'), y: day.score })
    })

    return (
      <div className={classNames('page allcore360', !isVisible && 'shrink')}>
        <h2>Rides</h2>
        <ul className="flexRow rideTotals">
          <li>
            <div>
              <span>{this.state.rideData.totalMonth}</span>
              This Month
            </div>
          </li>
          <li>
            <div>
              <span>{this.state.rideData.totalQuarter}</span>
              This Quarter
            </div>
          </li>
          <li>
            <div>
              <span>{this.state.rideData.totalYear}</span>
              This Year
            </div>
          </li>
          <li>
            <div>
              <span>{this.state.rideData.allTime}</span>
              All Time
            </div>
          </li>
          {
            this.state.rideData.remaining &&
            <li>
              <span style={{ color: theme.palette.secondary.main }}>8</span>Remaining
            </li>
          }
        </ul>

        <h2>Your Results</h2>

        <TabsDateRange activeTab={this.state.activeTab} action={(activeTab) => this.handleTabChange(activeTab, this.state.rideHistoryStart, this.state.rideHistoryEnd)}/>

        {
          this.state.dateSelectHistory &&

          <div className="flexRow dateRange">
            <TextField
              type="date"
              id="dateFrom"
              classes={{ root: 'form-group' }}
              onChange={(event) => this.handleHistoryDateChange(event, 'start')}
              value={this.state.rideHistoryStart}
            />
            <i className="icon calendar"></i>
            <TextField
              type="date"
              id="dateTo"
              classes={{ root: 'form-group' }}
              onChange={(event) => this.handleHistoryDateChange(event, 'end')}
              value={this.state.rideHistoryEnd}
            />
          </div>
        }

        <SwipeableViews
          animateHeight={false}
          index={this.state.graphSetIndex}
          onChangeIndex={this.onGraphSwipe}>

          <LineGraph
            maxY={this.state.activeTab === 'thisMonth' ? 90 : 'auto'}
            minY={this.state.activeTab === 'thisMonth' ? 0 : 'auto'}
            type={'allcore360'}
            graphSet='coreScore'
            data={[{ id: 'Core Score', data: coreScoreData }]}
          />

          <LineGraph
            maxY={this.state.activeTab === 'thisMonth' ? 90 : 'auto'}
            minY={this.state.activeTab === 'thisMonth' ? 0 : 'auto'}
            type={'allcore360'}
            graphSet='rideData'
            data={[{ id: 'Average Angle', data: graphAngleData }, { id: 'Total Spins', data: graphSpinData }]}
          />

          <LineGraph
            type={'allcore360'}
            graphSet='coreEvaluation'
            data={[{ id: 'Core Evaluation', data: coreEvaluationData }]}
          />

        </SwipeableViews>

        <div style={{ marginTop: -5, height: 40, marginBottom: 15 }}>
          <Tabs
            variant="fullWidth"
            value={this.state.graphSet}
            onChange={this.changeGraphSet}
            classes={{ root: 'tabsSecondary', indicator: 'tabsIndicator' }}
            style={{ border: '1px solid ' + theme.palette.secondary.main }}>
            <Tab style={{ borderRight: '1px solid ' + theme.palette.secondary.main }} disableRipple value='coreScore' key={0} label="Core Score" />
            <Tab style={{ borderRight: '1px solid ' + theme.palette.secondary.main }} disableRipple value='rideData' key={1} label="Ride Data" />

            <Tab disableRipple value='coreEvaluation' key={2} label="Evaluations" />
          </Tabs>
        </div>

        <ul className="legend flexRow">
          {
            this.state.graphSet === 'coreScore' &&
            <li>
              <span className="legendKey" style={{ backgroundColor: theme.palette.secondary.main }}></span>
              <span>Core Score</span>
            </li>
          }
          {
            this.state.graphSet === 'rideData' &&
            <li>
              <span className="legendKey" style={{ backgroundColor: theme.palette.secondary.main }}></span>
              <span>Average Angle</span>
            </li>
          }
          {
            this.state.graphSet === 'rideData' &&
            <li>
              <span className="legendKey" style={{ backgroundColor: theme.palette.primary.light }}></span>
              <span>Total Spins</span>
            </li>
          }
          {
            this.state.graphSet === 'coreEvaluation' &&
            <li>
              <span className="legendKey" style={{ backgroundColor: theme.palette.secondary.main }}></span>
              <span>Evaluation Score</span>
            </li>
          }
        </ul>

        {
          this.state.rideHistory.length < 1 &&
          <p style={{ textAlign: 'center', marginBottom: '-10px', marginTop: 10 }}>No rides found during this time period.</p>
        }

        {
          this.state.rideHistory.length > 0 &&
          <div>
            <h2>Ride History</h2>

            <div className="switchWrapper flexRow">
              <span onClick={() => this.setState({ evalOnly: false })} style={{ fontWeight: this.state.evalOnly ? 'normal' : 'bold' }}>All Rides</span>
              <Switch
                className="switch"
                checked={this.state.evalOnly}
                onChange={this.toggleEvalOnly}
                value="evalOnly"
              />
              <span onClick={() => this.setState({ evalOnly: true })} style={{ fontWeight: this.state.evalOnly ? 'bold' : 'normal' }}>Evaluations Only</span>
            </div>

            <ListExpandable runRideAgain={this.props.runRideAgain} onUpdate={() => this.getRideDataContent()} data={this.state.rideHistory} type="allcore360" evalOnly={this.state.evalOnly} patient={this.props.currentPatient} />
          </div>
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(AllCore360)
